<template>
  <!-- Team List: Reorderable -->
  <div class="team-list">
    <draggable
      v-model="teams"
      v-bind="dragOptions"
      @start="drag = true"
      @end="drag = false"
    >
      <transition-group
        type="transition"
        :name="!drag ? 'flip-list' : null"
      >
        <AppSidebarTeamSingle
          v-for="team in teams"
          :key="team.id"
          draggable
          :team="team"
        />
      </transition-group>
    </draggable>
  </div>
</template>
<script>
import draggable from 'vuedraggable'
const AppSidebarTeamSingle = () => import('./AppSidebarTeamSingle')

export default {
  name: 'AppSidebarTeams',

  components: {
    AppSidebarTeamSingle,
    draggable
  },

  data () {
    return {
      drag: false
    }
  },

  computed: {
    dragOptions () {
      return {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost'
      }
    },

    teams: {
      get () {
        return this.$store.getters['teams/teams']
      },
      set (value) {
        let order = value.map(row => row.id)
        this.$store.dispatch('teams/reorder', { order: order })
      }
    }
  }
}
</script>
